export const testIds = {
  // common
  backgroundTaskManager: 'background-task-manager',
  exceedDeviceLimitContinueHere: 'exceed-device-limit-continue-here',
  exceedDeviceLimitContainer: 'exceed-device-limit-container',
  accountSettingsMenuTrigger: 'account-settings-menu-trigger',
  appUpdateDialog: 'app-update-dialog',
  appUpdateDialogCloseButton: 'app-update-dialog-close-button',
  // inbox
  inboxPage: 'inbox-page',
  inboxMenu: 'inbox-menu',
  inboxMenuToggle: 'inbox-menu-toggle',
  inboxSearchConversationsFilterTab: 'inbox-search-conversations-filter-tab',
  inboxConversationsSearchbar: 'inbox-conversations-search-bar',
  inboxConversationsStatusFilterButton:
    'inbox-conversations-status-filter-button',
  inboxFilterConversationTrigger: 'inbox-filter-conversation-trigger',
  inboxConversationWindow: 'inbox-conversation-window',
  inboxCollaboratorsMenuTrigger: 'inbox-collaborators-menu-trigger',
  inboxFilterLabelsPanel: 'inbox-filter-labels-panel',
  inboxMyInboxMenuItem: 'my-inbox-menu-item',
  inboxCompanyInboxMenuItem: 'company-inbox-menu-item',
  inboxAddCollaboratorsMenu: 'inbox-add-collaborators-menu',
  inboxAddCollaboratorsButton: 'inbox-add-collaborators-button',
  inboxCollaboratorsSearchInput: 'inbox-collaborators-search-input',
  inboxAddCollaboratorsList: 'inbox-add-collaborators-list',
  inboxCollaboratorMenuExistingCollaboratorsList:
    'inbox-collaborator-menu-existing-collaborators-list',
  inboxExistingCollaboratorsList: 'inbox-existing-collaborators-list',
  inboxConversationInput: 'inbox-conversation-input',
  inboxSavedReplySuggestions: 'inbox-saved-reply-suggestions',
  inboxConversationUserProfile: 'inbox-conversation-user-profile',
  inboxSearchConversationList: 'search-conversations-list',
  inboxUnassignedTab: 'inbox-unassigned-tab',
  inboxAssignedTab: 'inbox-assigned-tab',
  // channels
  channelsPage: 'channels-page',
  channelsSecondaryMenu: 'channels-secondary-menu',
  channelsWhatsappCloudAPIChannelsTabs: 'whatsapp-cloudapi-channels-tabs',
  channelsWhatsappCloudAPIFbAccountDropdown:
    'whatsapp-cloudapi-fb-account-dropdown',
  // flow builder
  flowBuilderNodeFormDrawer: 'node-form-drawer',
  // contacts
} as const;
