import { createQueryKeys } from '@lukemorales/query-key-factory';
import { useMutation, useQuery } from '@tanstack/react-query';

import { useAxios } from '@/api/axiosClient';
import { FeatureType } from '@/api/ipWhitelists';
import {
  ApiErrorResponseTemplate,
  ApiSuccessResponseTemplate,
  RbacDefaultRole,
} from '@/api/types';

export type ExperimentalFeature = {
  sleekflow_company_id: string;
  sleekflow_staff_id: string;
  created_at: string;
  updated_at: string;
  feature_id: string;
  id: string;
  sys_type_name: string;
  is_enabled: boolean;
};

export const tenentHubKeys = createQueryKeys('tenant-hub', {
  experimentalFeatures: null,
  enabledExperimentalFeatures: null,
  allRoles: (companyId: string) => [companyId],
});

export function useUpsertStaffExperimentalFeatures() {
  const axios = useAxios();

  return useMutation({
    mutationFn: async (data: {
      staff_experimental_features: {
        feature_id: string;
        is_enabled: boolean;
      }[];
    }) => {
      const result = await axios.post<
        | ApiSuccessResponseTemplate<{
            company_features: ExperimentalFeature[];
            user_features: ExperimentalFeature[];
          }>
        | ApiErrorResponseTemplate
      >(
        '/v1/tenant-hub/authorized/ExperimentalFeatures/UpsertStaffExperimentalFeatures',
        data,
        {
          baseURL: import.meta.env.VITE_SLEEKFLOW_API_BASE_URL,
        },
      );

      if (!result.data.success) {
        throw new Error(result.data.message);
      }

      return result.data;
    },
  });
}

export function useUpsertCompanyExperimentalFeatures() {
  const axios = useAxios();

  return useMutation({
    mutationFn: async (data: {
      company_experimental_features: {
        feature_id: string;
        is_enabled: boolean;
      }[];
    }) => {
      const result = await axios.post<
        ApiSuccessResponseTemplate<{
          company_features: ExperimentalFeature[];
          user_features: ExperimentalFeature[];
        }>
      >(
        '/v1/tenant-hub/authorized/ExperimentalFeatures/UpsertCompanyExperimentalFeatures',
        data,
        {
          baseURL: import.meta.env.VITE_SLEEKFLOW_API_BASE_URL,
        },
      );

      return result.data;
    },
  });
}

export function useGetExperimentalFeatures() {
  const axios = useAxios();

  return useQuery({
    queryKey: tenentHubKeys.experimentalFeatures,
    queryFn: async () => {
      const result = await axios.post<
        ApiSuccessResponseTemplate<{
          company_features: FeatureType[];
          staff_features: FeatureType[];
        }>
      >(
        '/v1/tenant-hub/authorized/ExperimentalFeatures/GetExperimentalFeatures',
        {},
        {
          baseURL: import.meta.env.VITE_SLEEKFLOW_API_BASE_URL,
        },
      );

      return result.data;
    },
  });
}

export function useGetEnabledExperimentalFeatures() {
  const axios = useAxios();
  return useQuery({
    queryKey: tenentHubKeys.enabledExperimentalFeatures,
    queryFn: async () => {
      const result = await axios.post<
        ApiSuccessResponseTemplate<{
          enabled_company_features: ExperimentalFeature[];
          enabled_staff_features: ExperimentalFeature[];
        }>
      >(
        '/v1/tenant-hub/authorized/ExperimentalFeatures/GetEnabledExperimentalFeatures',
        {},
        {
          baseURL: import.meta.env.VITE_SLEEKFLOW_API_BASE_URL,
        },
      );
      return result.data;
    },
  });
}

type SharedInvitationAcceptParams = {
  username: string;
  lastName: string;
  firstName: string;
  displayName: string;
  position: string;
  timeZoneInfoId: string;
  phoneNumber: string;
  password: string;
};
type LinkInvitationRequest = {
  shareableId: string;
  invite_shared_user_object: SharedInvitationAcceptParams & {
    email: string;
    confirmPassword: string;
  };
  location: string;
};
export type LinkInvitationResponse = {
  data: null;
  date_time: string;
  error_code: number;
  error_context: {
    Code: number;
    InnerException: null;
  };
  http_status_code: number;
  message: string;
  request_id: string;
  success: boolean;
};

export function useCompleteLinkInvitation({
  onSuccess,
  onError,
}: {
  onSuccess?: (data: LinkInvitationResponse) => void;
  onError?: (error: Error) => void;
}) {
  const axios = useAxios();

  return useMutation({
    mutationFn: async (data: LinkInvitationRequest) => {
      const result = await axios.post(
        '/v1/tenant-hub/invite/CompleteLinkInvitation',
        data,
        {
          baseURL: import.meta.env.VITE_SLEEKFLOW_API_BASE_URL,
        },
      );

      if (!result.data.success) {
        throw new Error(result.data.message);
      }

      return result.data;
    },
    onSuccess,
    onError,
  });
}

type EmailInvitationRequest = {
  username: string;
  sleekflow_user_id: string | null;
  tenanthub_user_id: string | null;
  displayName: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  password: string;
  token: string | null;
  position: string;
  timeZoneInfoId: string;
  location: string | null;
};

export type EmailInvitationResponse = {
  success: boolean;
  data: {
    message: string;
  };
  message: string;
  date_time: string;
  http_status_code: number;
  error_code: number;
  error_context: {};
  request_id: string;
};

export default function useCompleteEmailInvitation({
  onSuccess,
  onError,
}: {
  onSuccess?: (data: EmailInvitationResponse) => void;
  onError?: (error: Error) => void;
}) {
  const axios = useAxios();

  return useMutation({
    mutationFn: async (data: EmailInvitationRequest) => {
      const result = await axios.post(
        '/v1/tenant-hub/invite/CompleteEmailInvitation',
        {
          ...data,
          location: data.location || 'eastasia',
        },
        {
          baseURL: import.meta.env.VITE_SLEEKFLOW_API_BASE_URL,
        },
      );

      if (!result.data.success) {
        throw new Error(result.data.message);
      }

      return result.data;
    },
    onSuccess,
    onError,
  });
}

export type Role = {
  id: string;
  name: RbacDefaultRole | string;
  description: string;
  user_count: number;
};

export type GetAllRolesResponse = ApiSuccessResponseTemplate<{
  roles: Role[];
}>;

export function useGetAllRolesQuery({
  companyId,
  enabled,
}: {
  companyId: string;
  enabled?: boolean;
}) {
  const axios = useAxios();

  return useQuery({
    queryKey: tenentHubKeys.allRoles(companyId),
    queryFn: async () => {
      const result = await axios.post<
        GetAllRolesResponse | ApiErrorResponseTemplate
      >(
        '/v1/tenant-hub/authorized/Rbac/GetAllRolesInCompany',
        { company_id: companyId },
        {
          baseURL: import.meta.env.VITE_SLEEKFLOW_API_BASE_URL,
        },
      );

      if (!result.data.success) {
        throw new Error(result.data.message);
      }
      return result.data.data;
    },
    enabled,
  });
}
