export const ROUTES = {
  inbox: 'inbox',
  tickets: 'tickets',
  ticketsAll: 'tickets/all',
  ticketsOverdue: 'tickets/overdue',
  ticketsUrgent: 'tickets/urgent',
  ticketsHighPriority: 'tickets/high-priority',
  ticketsOnHold: 'tickets/on-hold',
  ticketsTodo: 'tickets/to-do',
  ticketsResolved: 'tickets/resolved',
  inboxConversationTab: 'inbox/:conversationTab',
  automations: 'automations',
  invite: 'invite',
  // Broadcasts
  broadcasts: 'broadcasts',
  broadcastsId: 'broadcasts/:id',
  broadcastChannelCreate: 'broadcasts/:channel',
  broadcastsChannelIdDraft: 'broadcasts/:channel/:templateId/edit',
  broadcastsChannelTemplateIdReview: 'broadcasts/:channel/:templateId/review',
  // Contacts
  contacts: 'contacts',
  contactsTeam: 'contacts/team',
  contactsMyContacts: 'contacts/my-contacts',
  contactsShopify: 'contacts/shopify',
  contactsId: 'contacts/:id',
  contactsIdTab: 'contacts/:id/:tab',
  contactsImport: 'contacts/import',
  contactsCreate: 'contacts/create',
  contactsList: 'contacts/list',
  contactsListId: 'contacts/list/:id',
  contactsCustomObject: 'contacts/:id/custom-objects',
  // Analytics
  analytics: 'analytics',
  integrations: {
    index: 'integrations',
    setup: {
      salesforce: {
        init: '/integrations/salesforce/setup',
        finish: '/integrations/salesforce/setup/finish',
      },
      facebookLeadAd: {
        init: '/integrations/facebook-lead-ad/setup',
      },
      salesforceMarketingCloud: {
        setup: '/integrations/salesforce-marketing-cloud/setup',
      },
      platformApi: {
        setup: '/integrations/platform-api/setup',
      },
      zapier: {
        setup: '/integrations/zapier/setup',
      },
      make: {
        setup: '/integrations/make/setup',
      },
    },
    dashboard: {
      salesforce: '/integrations/salesforce',
      facebookLeadAd: '/integrations/facebook-lead-ad',
      facebookLeadAdMappingInit:
        '/integrations/facebook-lead-ad/mapping/:formId/init',
      facebookLeadAdMappingManage:
        '/integrations/facebook-lead-ad/mapping/:formId/manage',
      salesforceSettings: '/integrations/salesforce/:id/user-mapping',
    },
  },
  analyticsConversations: 'analytics/conversations',
  analyticsSales: 'analytics/sales',
  commerceHub: 'commerce-hub',
  gettingStarted: 'getting-started',
  // Channels
  channels: 'channels',
  whatsappCloudApi: 'channels/whatsapp',
  whatsapp360dialog: 'channels/360dialog',
  whatsappTwilio: 'channels/twilio',
  facebook: 'channels/messenger',
  sms: 'channels/sms',
  wechat: 'channels/wechat',
  line: 'channels/line',
  telegram: 'channels/telegram',
  viber: 'channels/viber',
  instagram: 'channels/instagram',
  connectMetaConversionApi: 'channels/meta-conversion-api/connect',
  liveChatWidget: 'channels/live-chat-widget',
  connectInstagram: 'channels/instagram/connect',
  connectWechat: 'channels/wechat/connect',
  connectLine: 'channels/line/connect',
  connectTelegram: 'channels/telegram/connect',
  connectSMS: 'channels/sms/connect',
  connectViber: 'channels/viber/connect',
  connectFacebook: 'channels/messenger/connect',
  connectWhatsappCloud: 'channels/whatsapp/whatsapp-cloud-api/connect',
  // Whatsapp Templates
  whatsappTemplate: 'channels/whatsapp/template',
  templateCreate: 'channels/whatsapp/template/:wabaId/create/',
  // Flow builder
  flowBuilder: 'flow-builder',
  flowBuilderListing: 'flow-builder/listing',
  flowBuilderUsage: 'flow-builder/usage',
  flowBuilderId: 'flow-builder/:id',
  flowBuilderIdEditor: 'flow-builder/:id/editor',
  flowBuilderIdLog: 'flow-builder/:id/log',
  flowBuilderIdSettings: 'flow-builder/:id/settings',
  // Settings
  settings: 'settings',
  settingsCompanyDetail: 'settings/company-details',
  settingsCompanyDetail2FA: 'settings/company-details/2fa',
  settingsCompanyDetailIpWhiteList: 'settings/company-details/ip-whitelist',
  settingsCustomObject: 'settings/custom-objects',
  settingsCustomObjectEdit: 'settings/custom-objects/:id',
  settingsCustomObjectCreate: 'settings/custom-objects/create',
  settingsContactPropertiesDefault: 'settings/contact-properties/default',
  settingsContactPropertiesPersonal: 'settings/contact-properties/personal',
  settingsUserManagement: 'settings/user-management',
  settingsUserManagementEdit: 'settings/user-management/:id',
  settingsTeamManagement: 'settings/team-management',
  settingsSubscriptions: 'settings/subscriptions',
  settingsInboxSettings: 'settings/inbox-settings',
  settingsTicketing: 'settings/ticketing',
  settingsInvoices: 'settings/invoices',
  settingsLabels: 'settings/labels',
  settingsSupportServices: 'settings/support-services',
  settingsSubscriptionsManagePlan: 'settings/manage-plan',
  settingsSubscriptionsManagePlanSubscribePlan:
    'settings/manage-plan/subscribe-plan',
  settingsAddOns: 'settings/add-ons',
  settingsAuditLog: 'settings/audit-log',
  settingsAddOnsAdding: 'settings/add-ons/adding',
  settingsDeletedContacts: 'settings/deleted-contacts',
  settingsSleekFlowLab: 'settings/sleekflow-lab',
  settingsDataPrivacy: 'settings/data-privacy',
  settingsIntegrationDisconnectionAlerts:
    'settings/integration-disconnection-alerts',
  settingsRolesAndPermissions: 'settings/roles-and-permissions',
  settingsRolesAndPermissionsEdit: 'settings/roles-and-permissions/:id',
  settingsRolesAndPermissionsCreate: 'settings/roles-and-permissions/create',
  // AI Settings
  aiSettings: 'ai-settings',
  aiSettingsUploadSource: 'ai-settings/upload',
  // Custom Object Data
  customObjectData: 'custom-objects-data/:id',
  customObjectDataIndex: 'custom-objects-data',
  // Public routes
  invitationLink: 'invitation-link/:type/:id/:location',
} as const;
